
import HomePage from '../pages/home.f7.html';  
import single from '../pages/single.f7.html';  
import apps from '../pages/apps.f7.html';  
import rhome from '../comps/rhome.f7.html';
import png from '../comps/png.f7.html';
import Framework7 from 'framework7';

let transition = () => {
  if (Framework7.device == 'desktop' || Framework7.device == 'ipad' || window.innerWidth >= 750) {
    return 'f7-circle'
  }
  else {
    return 'f7-parallax'
  }
}

let defPopup = {
  closeOnEscape: true,
  swipeToClose: 'to-bottom',
  on: {
    open: (e) => {
    },
    opened: (e) => {
      // let backdrop = document.querySelector('.popup-backdrop')
      // backdrop.classList.add('opened')
      // $(e.$el[0]).find('.page-content').css('display', 'block')
      // setTimeout(() => {
      //   $(e.$el[0]).find('.page-content').css('opacity', '1')
      // }, 10);


    },
    close: (e) => {
      let backdrop = document.querySelector('.popup-backdrop')
      backdrop.classList.remove('opened')

    },
    closed: (e) => {
      let backdrop = document.querySelector('.popup-backdrop')
      backdrop.style.transition = ''
      backdrop.classList.remove('opened')
    },
  }
}


var routes = [
  {
    path: '/',
    component: rhome,
    id: 'tab1',
    options: {
      transition: transition(),
    },
  },
  {
    path: '/p',
    component: png,
    id: 'tab2',
    options: {
      transition: transition(),
    },
  },
  {
    path: '/:id/',
    component: single,
    options: {
      transition: transition(),
    },
    // beforeEnter:function({resolve, to, from, router}){
    //   if (document.querySelectorAll('.page[data-name=single]').length>0) {
    //     document.querySelector('.page[data-name=single]').parentNode.removeChild(document.querySelector('.page[data-name=single]'))
    //     router.history.splice(router.history.length-1, 1)
    //     console.log(router);
    //     // debugger
    //   }
    //   // console.log(to);
    //   // console.log(from);
      
    //   resolve()
    // }
    // asyncComponent: () => import('../css/singleCss.css')
    
  },
  {
    path: '/app/:id',
    options: {
      transition: transition(),
    },   
    popup: { 
      component: apps,
      swipeHandler: '#appsHandler',
      ...defPopup
    }
  }
];

export default routes;