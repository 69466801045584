/** @jsx $jsx */
import { $jsx } from 'framework7';
import post_type from "./post_type.f7.html";
import post_swiper from "./post_swiper.f7.html";

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update;
  var reload = props.ref == 'post' ? 'true' : 'false';
  console.log(props.post);

  function remember() {
    app.store.state.appData.myRoute = 1;
  }

  $f7.on('e-liked', function (li) {
    $update();
  }); // if(props.ref!=undefined){$store.state.appData.ref='main'; console.log('yes')}else{console.log('no')}

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  ${props.post ? $h`
    <article class="post">
      <${post_type} type=${props.post.type} date=${props.post.published.date} />
      ${localStorage['m-like-'+props.post._id]=='1' && $h`
      <i class="f7-icons ttip" ttipText="Вам это понравилось" >heart_fill</i>
      `}
      <div class="desc">
        <div class="text">
          ${props.post.shorttext}
        </div>
        <a href="/${props.post.name}/" @click=${remember} data-reload-current="${reload}">
          <button class="col button button-outline button-raised">посмотреть</button>
        </a>
      </div>
      <${post_swiper} reload=${reload} post=${props.post} />
    </article>
  `:$h`
  <div></div>`}
`
    }
    ;
}

framework7Component.id = 'f74d3406aa';
export default framework7Component;