
import { createStore } from 'framework7';


const store = createStore({
  state: {
    count: 10,
    appData: {
      tab: '1',
      events: {},
      taken: 'ae636c370ec27b30683b0e89d1f86221b6dc7c2116b60f92d1b47ce8676452fbaab5ba723aed8df6196d729be20e85fdd677d2d1380e76c75d34fb60f5874a44cdf4cbf72dc1cb2a2cf086bc27a0ad3ec52b8e6b37ff55b97e8466ec4480bb9d0ce703fa043d2c96b0e09da31d9f013a272a9587d424b74669d3cbaf5a77142b',
      url: 'ichuk.ru',
      cockUrlGet: `admin.ichuk.ru/api`,
      lastLike: 0,     //id поcта где был последний поставлен лайк юзером
      msvidoeplayed: false, //если видео на главном экране проигралось
      activeCategory: 'all',
      headers: ['', '', ''],
      curHeader:0,
    },
    data: {
      contacts: [
        {
          text: 'телеграм',
          href: 'https://t.me/chuk2004',
          disc: 'написать мне',
          btn_bgcol: '#00a2ff',
          btn_bgcoln: '#00a2ff',
          img: 'telegIco.svg',
        },
        // {
        //   text: 'фрилансхант',
        //   href: 'https://chuk.dx.am',
        //   disc: 'заказать работу',
        //   btn_bgcol: '#c3ae19',
        //   btn_bgcoln: '#e5cf2f',
        //   img: 'freelIco.png'
        // },

      ],
      posts: [],
      pngs: [],
      categories: [
        {
          name: 'all',
          runame: 'всё',
          num: 10
        },
        {
          name: 'order',
          runame: 'заказы',
          num: 2
        },
        {
          name: 'update',
          runame: 'обновления',
          num: 5
        },
        {
          name: 'mywork',
          runame: 'свои проекты',
          num: 9
        },
        {
          name: 'dev',
          runame: 'разработчикам',
          num: 4
        },
      ]
    },
    gf: {
      support_format_webp() { var elem = document.createElement("canvas"); return !(!elem.getContext || !elem.getContext("2d")) && 0 == elem.toDataURL("image/webp").indexOf("data:image/webp") },
      getRandomInRange(min, max) { return Math.floor(Math.random() * (max - min + 1)) + min },
      getCookie(name) {
        let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : void 0
      },
      setCookie(name, value, options = {}) {
        (options = {
          path: "/",
          ...options
        }).expires instanceof Date && (options.expires = options.expires.toUTCString());
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
        for (let optionKey in options) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          !0 !== optionValue && (updatedCookie += "=" + optionValue)
        }
        document.cookie = updatedCookie
      },
      vibrate(ms) {
        /**
             * Вибрация
             * @param {number | array} ms длительность вибрации 
             */
        if (typeof (ms) == 'string') {
          switch (ms) {
            case 'step':
              ms = [22]
              break
            case 'lil success':
              ms = [15, 8, 30]
              break
            case 'big success':
              ms = [20, 10, 25, 10, 30]
              break
            case 'success':
              ms = [15, 7, 20, 7, 20]
              break
            case 'big fail':
              ms = [20, 8, 20, 8, 20, 50, 20]
              break
            case 'fail':
              ms = [30, 8, 20, 100, 30]
              break
            case 'warn':
              ms = [20, 20, 20, 20, 20, 90, 20]
              break

            default:
              break
          }

        }
        const canVibrate = window.navigator.vibrate
        if (canVibrate) window.navigator.vibrate(ms)
      },
    },
  },
})
export default store;
