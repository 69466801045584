/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $update = _ref.$update,
      $onMounted = _ref.$onMounted,
      $el = _ref.$el,
      $store = _ref.$store;
  var theme = $store.state.appData.theme !== undefined ? $store.state.appData.theme : 'light';

  var bgImg = function bgImg() {
    return "chukMainscreen" + ($store.state.appData.theme == 'dark' ? "Dark" : "Light") + "." + ($store.state.gf.support_format_webp() ? "webp" : "jpg");
  };

  var topng = function topng(props, _ref2) {
    var $h = _ref2.$h,
        $on = _ref2.$on;
    return function () {
      return $jsx("nav", {
        class: "mainScreen-tips"
      }, $jsx("div", {
        class: "mainScreen-tip",
        id: "activeTip"
      }, "\u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u0438\u0441\u0442 \u0434\u0438\u0437\u0430\u0439\u043D\u0435\u0440"));
    };
  };

  var tohome = function tohome(props, _ref3) {
    var $h = _ref3.$h;
    return function () {
      return $jsx("nav", {
        class: "mainScreen-tips"
      }, $jsx("a", {
        class: "mainScreen-tip tab-link",
        href: "#tab1",
        id: "disactiveTip"
      }, "\u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u0438\u0441\u0442 \u0434\u0438\u0437\u0430\u0439\u043D\u0435\u0440"));
    };
  };

  var t = true;
  $(document).on('click', '.tab-link', function (e) {
    if (e.target.href[e.target.href.length - 1] == 1) {
      t = true;
    } else {
      t = false;
    }

    $update();
  });
  $(document).on('click', '#activeTip', function (e) {
    $('.page-content.home').scrollTop(window.innerHeight, 600);
  });
  $(document).on('click', '#disactiveTip', function (e) {
    setTimeout(function () {
      $('.page-content.home').scrollTop(window.innerHeight, 600);
    }, 200);
  });
  $onMounted(function (e) {
    console.log(e); // $el.value[0].childNodes[0]
    // console.log($('.page-content.home').scrollTop());

    app.on('e-scroll', function () {
      // console.log($('.page-content.home').scrollTop());
      var px = $store.state.appData.desktop ? 115 : 65; // if ($('.page-content.home').scrollTop() <= px) {
      //   $el.value[0].style.borderRadius = `0px 0px ${$('.page-content.home').scrollTop()}px ${$('.page-content.home').scrollTop()}px`
      // } else $el.value[0].style.borderRadius = `0px 0px ${px}px ${px}px`
    });
    e[0].querySelector('img.bg').addEventListener('load', function () {
      setTimeout(function () {
        if (e[0].querySelector('img.bg').offsetWidth < window.innerWidth) {
          // e[0].querySelector('img.bg').style.height = (window.innerHeight + (window.innerWidth - e[0].querySelector('img.bg').offsetWidth) / 1.77) + "px"
          e[0].querySelector('img.bg').style.animation = 'none';
          e[0].querySelector('img.bg').style.width = '100vw';
          e[0].querySelector('img.bg').style.objectFit = 'cover';
        }
      }, 20);
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <header id="mainScreen" class="mainScreen">
    <img class="bg" src="static/img/${bgImg()}" style="animation-duration: ${(1920-window.innerWidth)*20}ms;"/>
    <img class="logo" src="static/img/chukIcoKrivieWhite.svg" alt=""/>
    ${t && $h`
      <${topng} /> 
      `} ${!t && $h`
      <${tohome} /> 
    `}
  </header>
`
    }
    ;
}

framework7Component.id = 'c2251775a5';
export default framework7Component;