import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import 'highlight.js/styles/monokai-sublime.min.css';

hljs.registerLanguage('css', css);
hljs.registerLanguage('javascript', javascript);

export function code(items) {
	const highlightedCode = hljs.highlight(
		items[3].replace('<br>','').replaceAll('<br>','\n'),
		{ language: items[1] }
		).value
		// debugger
	return `<code><top><circls><c></c><c></c><c></c></circls><name>${items[2].split(' ').join('_') +'.' +items[1]}</name></top>${highlightedCode}</code>`
}