/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $h = _ref.$h,
      $update = _ref.$update;

  var img = function img() {
    var _props$data, _props$data$fav, _props$data$fav$data, _props$data$fav$data$;

    return ((_props$data = props.data) === null || _props$data === void 0 ? void 0 : (_props$data$fav = _props$data.fav) === null || _props$data$fav === void 0 ? void 0 : (_props$data$fav$data = _props$data$fav.data) === null || _props$data$fav$data === void 0 ? void 0 : (_props$data$fav$data$ = _props$data$fav$data.attributes) === null || _props$data$fav$data$ === void 0 ? void 0 : _props$data$fav$data$.url) || false;
  };

  $f7.on('e-update', function () {
    $update();
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div> ${props.data.color!==null && $h` <a class="linkBtn margin-top link external" href="${props.data.href}"
      style="background-color: ${props.data.bgcolor}; color: ${props.data.color}; box-shadow:${props.data.boxshadow}">
      ${img()!==false && $h` <img src="https://admin.${$store.state.appData.url}${img()}" alt="" /> `}
      <span>посмотреть сайт</span>
    </a> `} </div>
`
    }
    ;
}

framework7Component.id = 'f6d56d1c79';
export default framework7Component;