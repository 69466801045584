import $ from "dom7";
import { s_tabs } from "../s-tabs";
import { s_ms_tips } from "../s-ms-tips";
import store from "../../store";
import { app } from "../../app";

export function s_scroll_event(e) {
  var scrolling = false;
  let event;

  document.addEventListener("scroll",
    function (e) {
      scrolling = true;
      event = e
    },
    {
      capture: true,
      passive: true
    }
  );

  setInterval(function () {
    if (scrolling) {
      scrolling = false;

      if (
        event.target.className.includes("page-content") &&
        event.target.className.includes("home")
      ) {
        if (!store.state.appData.desktop) {
          s_tabs("page-content");
        }
        app.emit('e-scroll')
      } else { app.emit('e-scroll-glob') }
      
      if (!store.state.appData.desktop) {
        let closestIfin = -1
        let closestItemp = -1
        if (event.target.className.includes("single")) {
          let page = app.view.current.router.currentPageEl
          let headers = Array.from(page.querySelectorAll('h2'))
          let top = page.firstChild.scrollTop
          window.ttop = top
          let pxs = []
          let closestPx = 999999999999999999
          // debugger
          headers.forEach((el, i) => {
            let pxs = headers[i].offsetTop
            let div = (top - pxs) + 250
            if (div > 0 && div < closestPx) {
              closestItemp = i
              closestPx = div
            }
          })
          // debugger
          // console.log(headers[closestItemp])

        }

        if ((closestItemp != closestIfin) && closestItemp != -1) {
          let leftPx = document.querySelector(`.scrollHeaders li[link="h${closestItemp}"]`).offsetLeft
          $('.scrollHeaders').scrollLeft(leftPx - 15, 200)
          closestIfin = closestItemp
          // console.log('scrol')
        }
      }

    }
  }, 170);

  $(document).on("click", ".mainScreen-tip", (e) => {
    s_ms_tips(e); //делает ссылку с меню на главном экране активной
  });
  $(document).on("click", ".vibrate50", (e) => {
    navigator.vibrate(30)
  });
  $(document).on("click", ".vibrate100", (e) => {
    navigator.vibrate([10, 20, 20])
  });
}
