import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/** @jsx $jsx */
import { $jsx } from 'framework7';
import css from 'bundle-loader?lazy!../css/singleCss.less';
css();
import { content_parse } from '../comps/single/content_parse.js';
import linkBtn from '../comps/single/linkBtn.f7.html';
import last_post from '../comps/single/last_post.f7.html';
import footer from '../comps/single/footer.f7.html';
import toolbar from '../comps/single/toolbar.f7.html';
import contentComponent from '../comps/single/contentComponent.f7.html';
import like from '../comps/single/like.f7.html';
import qs from 'qs';
import '../css/comments.less';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update,
      $ = _ref.$,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onMounted = _ref.$onMounted;

  var moment = require('moment');

  var rellax = 0;
  var bottomPadding = 0;
  var commentsInterval;
  moment.lang('ru');
  var proData = {
    content: function content() {}
  };
  var rawData;
  var loaded = false;
  $f7.preloader.show();
  console.log(props.id);
  var query = qs.stringify({
    populate: ["published", "mainscreen", "btn", "btn.fav"]
  }, {
    encodeValuesOnly: true
  });
  console.log(query);
  fetch("https://".concat(store.state.appData.cockUrlGet, "/icp-post?token=").concat(store.state.appData.taken, "&filters[name][$eq]=").concat(props.id, "&populate[0]=published&populate[1]=mainscreen&populate[2]=btn&populate[3]=btn.fav&populate[4]=thumbwebp"), {
    method: 'GET',
    headers: {
      'Authorization': "Bearer ".concat(store.state.appData.taken)
    }
  }).then(function (response) {
    return response.json();
  }).then(function (res) {
    rawData = _objectSpread(_objectSpread({}, res.data[0].attributes), {}, {
      id: res.data[0].id
    });
    loaded = true; // debugger
    // debugger

    proData = {
      date: function date() {
        return moment(rawData.published.date).format('DD MMMM YYYY');
      },
      content: function content() {
        return content_parse(rawData, $f7);
      }
    };
    $f7.emit('e-update');
    document.title = rawData.mainscreen.title;
    $f7.preloader.hide();
    $f7.on('e-lastPostUpdated', function () {
      $f7.lazy.create('.page-content.single');
    });
  });
  setInterval(function () {
    var field = document.getElementsByClassName('Button__StyledButton-sc-1nv7ojl-0')[0];

    if (field) {
      field.disabled = !document.querySelector('.ql-editor:not(.ql-blank)');
    }
  }, 500);
  $f7.on('e-update', function () {
    $update(function () {
      if (!rellax) {
        setTimeout(function () {
          rellax = new Rellax('.paralBlock', {
            wrapper: '.page-content.single',
            center: true
          });
        }, 200);
      }

      setTimeout(function () {
        if (!$store.state.appData.desktop && !bottomPadding && q('.top.scrollHeaders').height() != 0) {
          $('.page-content.single').css('padding-bottom', q('.top.scrollHeaders').height() + q('.toolbar.toolbar-bottom.nav').height() - 15 + 'px');
          bottomPadding = 1;
        }
      }, 400);
    });
  });
  var waitLoadCommentsAt = location.pathname;
  var intObserver;
  $onMounted(function () {
    waitLoadCommentsAt = location.pathname;
    var options = {
      // root: document.querySelector( '#viewport' ), // я закомментил строку, чтобы использовать значение по умолчанию
      rootMargin: '50% 0px',
      root: document.querySelector('.page-next .page-content.single') || document.querySelector('.page-current .page-content.single')
    };

    function trueCallback(entries, observer) {
      entries.forEach(function (entry) {
        var target = entry.target,
            isIntersecting = entry.isIntersecting;

        if (isIntersecting) {
          console.log('сработало', entry.target);
          entry.target.src = entry.target.getAttribute('data-src');
        } // делаем что-либо для каждого переданного элемента (в нашем случае он один)

      });
    }

    intObserver = new IntersectionObserver(trueCallback, options); // callback-функция (возвратная функция)

    $f7.on('e-readyToIntersect', function (imgs) {
      console.log(imgs); // debugger

      imgs.forEach(function (el) {
        return intObserver.observe(el);
      });
    });
  });
  setTimeout(function () {
    console.log('initCommm', waitLoadCommentsAt == location.pathname, !window.AnyComment.CommentManager._methods.openPage);

    if (waitLoadCommentsAt == location.pathname && !window.AnyComment.CommentManager._methods.openPage) {
      window.AnyComment.CommentManager._methods.init();
    }
  }, 3000);
  $onBeforeUnmount(function () {
    var _intObserver;

    clearInterval(commentsInterval);
    (_intObserver = intObserver) === null || _intObserver === void 0 ? void 0 : _intObserver.disconnect();
    $f7.preloader.hide();
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="single">
    <div class="page-content single">
      <div class="cont"> ${loaded && $h` <header>
        <!-- <img src="https://admin.${$store.state.appData.url}${rawData.thumbwebp.data.attributes.url}" alt=""/> -->
          <div class="title-large">
            
            <a class="link ${!$store.state.appData.myRoute?'external':'back'}" href="${!$store.state.appData.myRoute?'https://'+$store.state.appData.url:''}">
            <i class="f7-icons size-75">arrow_lefts</i>
          </a>
            <h1>${rawData.mainscreen.shorttext}</h1>
          </div>
          <time class="time">${proData.date()}</time>
          <br />
          <${linkBtn} data=${rawData.btn} id=${rawData.id} />
        </header> `} <main>
          <${contentComponent} text=${proData.content()} />
        </main>
      </div> ${loaded && $h` ${$store.state.appData.desktop ? $h`
      <!-- <${like} id=${rawData.id} likes=${rawData.likes} />  --> <div></div>
      `:$h` <div></div> ` }
      <${last_post} thispost=${rawData} /> `}
      <div id="anycomment-app"></div>
      <div class="padding-horizontal">
        <script>
          AnyComment = window.AnyComment || []; AnyComment.Comments = [];
          AnyComment.Comments.push({
            "root": "anycomment-app",
            "app_id": 6116,
            "language": "ru"
          })
          var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
          s.src = "https://widget.anycomment.io/comment/embed.js";
          var sa = document.getElementsByTagName("script")[0];
          sa.parentNode.insertBefore(s, s.nextSibling);
        </script>
      </div>
      <${footer} />
    </div>
    <nav class="toolbar toolbar-bottom nav"> ${loaded && $h`
      <${toolbar} id=${rawData.id} likes=${rawData.likes} /> `}
    </nav>
  </div>
`
    }
    ;
}

framework7Component.id = '21faf3e21a';
framework7Component.style = "\n";
export default framework7Component;