import { app } from '../../js/app'
import $ from 'dom7';
import store from '../../js/store';
import { srcBuild } from './content_parse';

export function gallery(items) {
  // console.log(items)
  let arr = items.slice(1).map((it) => srcBuild(it))
  // console.log(arr)
  let src = [];
  let alt = [];
  let photos = []

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    src.push(element.split(',,')[0]);
    // debugger
    // if (element.split(',,')[0].slice(0, 5)=='cock/') {
      
    // }
    alt.push(element.split(',,')[1]);
    photos.push({ url: src[i], caption: alt[i] })
  }


  let myPhotoBrowserStandalone = app.photoBrowser.create({
    photos: photos,
    theme: 'dark',
    navbar: true,
    toolbar: false,
    navbarShowCount: true,
    routableModals: true,
    expositionHideCaptions: true,
    url: 'img',
    popupCloseLinkText: `<i class="f7-icons">${app.device.desktop ? 'multiply' : 'arrow_left'}</i>`,
    navbarShowCount: true,
    iconsColor: 'left',
    navbarOfText: 'из',
    renderNavbar: function (e) {
      return `
          <div class="navbar navbar-photo-browser navbar-photo-browser-dark">
              <div class="navbar-inner navbar-inner-centered-title sliding" style="justify-content: flex-start;">
                <div class="left">
                  <a class="link popup-close wide rem2_4Auto" data-popup=".photo-browser-popup">
                    <i class="f7-icons">${app.device.desktop ? 'multiply' : 'arrow_left'}</i>
                  </a>
                </div>

                <div class="title" style="left: 0px;"><span class="photo-browser-current">1</span><span class="photo-browser-of">из</span><span class="photo-browser-total">4</span></div>

              </div>
            </div>`
    },
    swiper: {
      speed: 300,
      preloadImages: true,
      navigation: {
        nextEl: '.photo-browser-next',
        prevEl: '.photo-browser-prev',
      },
      zoom: {
        maxRatio: 2.4,
      },
      lazy: {
        enabled: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        invert: false,
      },
    },
  });

  myPhotoBrowserStandalone.on('close', function () {
    myPhotoBrowserStandalone.el.style.opacity = '0';
  })

  document.addEventListener("keydown", event => {
    if (event.isComposing || event.keyCode === 27) { //esc
      myPhotoBrowserStandalone.close();
      return;
    }
  });

  let id = `q${store.state.gf.getRandomInRange(0, 10000000000)}`;

  window.opo = myPhotoBrowserStandalone;
  $(document).on('click', `#${id}`, () => {
    myPhotoBrowserStandalone.open();
  });


  return `
    <div class="imgWrap">
      ${photos.length > 1 ? '<i class="f7-icons ttip" ttipText="несколько картинок">rectangle_fill_on_rectangle_angled_fill</i>' : ''}
      <img id="${id}" class="img" data-src="${src[0]}"/>
      ${store.state.appData.desktop ? '<img class="img under" data-src="' + src[0] + '"/>' : ''}
    </div>`
}