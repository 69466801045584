/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $on = _ref.$on,
      $store = _ref.$store,
      $f7 = _ref.$f7,
      $ = _ref.$,
      $update = _ref.$update;
  var ac4;
  setTimeout(function () {
    ac4 = $f7.actions.create({
      buttons: [// [
      //   {
      //     text: 'заказать работу',
      //     label: true
      //   },
      //   {
      //     text: 'фрилансхант',
      //     onClick: () => { window.location.href = '#' },
      //   },
      // ],
      [{
        text: 'написать мне',
        label: true
      }, {
        text: 'телеграм',
        onClick: function onClick() {
          window.location.href = 'https://t.me/chuk2004';
        }
      }], [{
        text: 'закрыть',
        color: 'red'
      }]]
    });
  }, 5);

  function swiperInit() {
    ac4.open();
  }

  var css = 'z-index:5;' + props.style;
  $f7.on('qTabsLoad', function () {
    $update();
    css = "z-index:5;transform: translate(-50%, -".concat($('.tabsB')[0].offsetHeight + 10, "px) !important;");
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div>
    ${!$store.state.appData.desktop && $h`
      <a href="#" @click=${swiperInit} style="${css}" class="contactsBtn ripple overflow-hidden ${props.class} ttip"
      ttipText="Ссылки на меня">
        я
      </a>
    `}
  </div>
`
    }
    ;
}

framework7Component.id = 'dab335adea';
export default framework7Component;