import { app } from '../../app'

export async function s_check_version(ms) {
  console.log('version')
  function doo() {
    
    if (navigator.onLine) {
      app.request({
        url: `https://${store.state.appData.url}/static/php/version.php`,
        cache: false,
        success: (res) => {
          if (localStorage.version == undefined) {
            localStorage.version = res
          } else if (res != localStorage.version) {
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            console.log("CLEEEARRR")
            try {
              caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                  caches.delete(cacheName);
                });
              });
            } catch (error) { }

            try {
              navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                  registration.unregister()
                }
              })
            } catch (error) { }

            localStorage.version = res

            // app.dialog.alert('Обнаружена новая версия приложения ' + res + '. Страница будет перезагружена', 'Обновление', () => {
            //   window.location.replace(window.location.href)
            // })

            app.dialog.create({
              title: 'Обновление',
              text: 'Обнаружена новая версия приложения ' + res + '. Страница будет перезагружена',
              cssClass:'upd',
              buttons: [{
                text:'Класс'
              }],
              on: {
                closed: function () {
                  window.location.replace(window.location.href)
                }
              }
            }).open()

          }
        }
      })
    }
  }

  doo()
  
 setInterval(() => {
   doo()
 }, ms);
  

}