/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $onMounted = _ref.$onMounted,
      $f7 = _ref.$f7;
  $onMounted(function () {
    $f7.emit('qTabsLoad');
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="toolbar-inner">
    <q-tabsButtItem text='house_fill' link='/rhome/' tab='1' cl='tab-link-active'></q-tabsButtItem>
    <q-tabsButtItem text='photo' link='/png/' tab='2' cl=''></q-tabsButtItem>
  </div>
`
    }
    ;
}

framework7Component.id = 'cbed1e3131';
framework7Component.style = "\n";
export default framework7Component;