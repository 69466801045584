import store from '../../store';
import { app } from '../../app'
window.store=store

export async function s_imports(e) {
  
  async function posts() {
    let ress = 0;
    if (navigator.onLine) {
    //   await app.request.post(`https://${store.state.appData.url}${store.state.appData.cockUrlGet}name?token=${store.state.appData.taken}`,
    //   {
    //     filter: {
    //       published: true
    //     },
    //     fields: {
    //       name: 1,
    //       thumbjpg: 1,
    //       thumbwebp: 1,
    //       type: 1,
    //       shorttext: 1,
    //       published: 1,
    //       gall: 1,
    //       mainscreen: 1,
    //       _id:1,
    //       likes: 1,
    //       _modified: 1
    //     },
    //     sort: { _created: -1 }
    //   }
    // )
    //   .then(function (res) {
    //     ress = (JSON.parse(res.data))
    //     store.state.data.posts = ress.entries;
    //     localStorage.posts=JSON.stringify(ress.entries);
    //     app.emit(`e-postsLoaded`, ress.entries);
    //     console.log(ress.entries);
    //   });

      
      fetch(`https://${store.state.appData.cockUrlGet}/icp-post?token=${store.state.appData.taken}&sort[0]=published.date:desc&populate[published]=*&populate[mainscreen]=*&populate[thumbwebp]=*&populate[gall]=*&fields[0]=name&fields[1]=name&fields[2]=type&fields[3]=shorttext&fields[4]=name&fields[5]=name&fields[6]=name&fields[7]=id&fields[8]=likes&fields[9]=updatedAt&pagination[pageSize]=10&pagination[page]=1`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${store.state.appData.taken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          let entries = data.data.map((el) => { return { ...el.attributes, id: el.id } })
          store.state.data.posts = entries;
          localStorage.posts = JSON.stringify(entries);
          // debugger
          console.log(entries);
          app.emit(`e-postsLoaded`, entries);
        })
        .catch(error => {
          console.error('Error:', error);
        });


      
      
    }else{
      console.log('try to load by cache');

      store.state.data.posts = JSON.parse(localStorage.posts);
      app.emit(`e-postsLoaded`, JSON.parse(localStorage.posts));
    }
    store.state.appData.postsLoaded=true;
  }

  async function about() {
    let ress = 0;
    if (navigator.onLine) {
    //   await app.request.post(`https://${store.state.appData.cockUrlGet}about?token=${store.state.appData.taken}`,
    //   {
    //     fields: {
    //       name: 1,
    //       text: 1,
    //       _modified: 1
    //     },
    //     sort: { _created: -1 }
    //   }
    // )
    //   .then(function (res) {
    //     ress = (JSON.parse(res.data))
    //     store.state.data.about = ress.entries;
    //     localStorage.about=JSON.stringify(ress.entries);
    //     app.emit(`e-aboutLoaded`);
    //   });
      

      fetch(`https://${store.state.appData.cockUrlGet}/pi-abouts?fields[0]=text&fields[1]=name`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${store.state.appData.taken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          store.state.data.about = entries(data);
          localStorage.about = JSON.stringify(entries(data));
          app.emit(`e-aboutLoaded`);
        })




    }else{
      store.state.data.about = JSON.parse(localStorage.about);
      app.emit(`e-aboutLoaded`);
    }
  }
  async function apps() {
    let ress = 0;
    if (navigator.onLine) {
    //   await app.request.post(`https://${store.state.appData.url}${store.state.appData.cockUrlGet}apps?token=${store.state.appData.taken}`,
    //   {
    //     fields: {
    //       name: 1,
    //       ico: 1,
    //       disc: 1,
    //       imgs: 1,
    //       href:1,
    //       _modified: 1
    //     },
    //     sort: { _created: -1 }
    //   }
    // )
    //   .then(function (res) {
    //     ress = (JSON.parse(res.data))
    //     store.state.data.apps = ress.entries;
    //     localStorage.apps=JSON.stringify(ress.entries);
    //     app.emit(`e-appsLoaded`);
    //   });



      fetch(`https://${store.state.appData.cockUrlGet}/pi-apps?populate=*&sort=id`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${store.state.appData.taken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          store.state.data.apps = entries(data);
          localStorage.apps = JSON.stringify(entries(data));
          app.emit(`e-appsLoaded`);
        })
      
      
    }else{
      store.state.data.apps = JSON.parse(localStorage.apps);
      app.emit(`e-appsLoaded`);
    }
  }
  async function skills() {
    let ress = 0;
    if (navigator.onLine) {
    //   await app.request.post(`https://${store.state.appData.url}${store.state.appData.cockUrlGet}apps?token=${store.state.appData.taken}`,
    //   {
    //     fields: {
    //       name: 1,
    //       ico: 1,
    //       disc: 1,
    //       imgs: 1,
    //       href:1,
    //       _modified: 1
    //     },
    //     sort: { _created: -1 }
    //   }
    // )
    //   .then(function (res) {
    //     ress = (JSON.parse(res.data))
    //     store.state.data.apps = ress.entries;
    //     localStorage.apps=JSON.stringify(ress.entries);
    //     app.emit(`e-appsLoaded`);
    //   });



      fetch(`https://${store.state.appData.cockUrlGet}/skills?populate=*&sort=id`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${store.state.appData.taken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          let ent = entries(data).sort((a, b) => +new Date(a.duration) - +new Date(b.duration))
          store.state.data.skills = ent;
          localStorage.skills = JSON.stringify(ent);
          app.emit(`e-skillsLoaded`);
        })
      
      
    }else{
      store.state.data.skillsLoaded = JSON.parse(localStorage.skillsLoaded);
      app.emit(`e-skillsLoadedLoaded`);
    }
  }
  async function pngs() {
    let ress = 0;
    if (navigator.onLine && 0) {
      console.log('[pngs]', `https://${store.state.appData.url}${store.state.appData.cockUrlGet}pngs?token=${store.state.appData.taken}`)
      await app.request.get(`https://${store.state.appData.url}${store.state.appData.cockUrlGet}pngs?token=${store.state.appData.taken}`)
      .then(function (res) {
        ress = (JSON.parse(res.data))
        store.state.data.pngs = ress.entries;
        window.store=store
        localStorage.pngs=JSON.stringify(ress.entries);
        app.emit(`e-pngsLoaded`);
      })
    }else{
      store.state.data.pngs = JSON.parse(localStorage.pngs);
      app.emit(`e-pngsLoaded`);
    }
  }

  await about()
  await apps()
  await skills()
  await posts()
  // await pngs()

}

function entries(data) {
  return data.data.map(el => el.attributes)
}

export async function stImages(data) {

  try {
    return Promise.all([data.data].flat().map(async (el) => {

      let rest = await fetch(`https://admin.ichuk.ru${el.attributes.url}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${store.state.appData.taken}`
        }
      })
      let blob = await rest.blob()


      return await blobToBase64(blob)
    }))
  } catch (error) {
    debugger
    console.log(error)
  }


  async function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}