/** @jsx $jsx */
import { $jsx } from 'framework7';
import css from 'bundle-loader?lazy!../css/apps.css';
css();
import { stImages } from '../js//mods//imports/s-imports.js';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update,
      $el = _ref.$el,
      $ = _ref.$,
      $onMounted = _ref.$onMounted,
      $onUnmounted = _ref.$onUnmounted;
  var entr = $store.state.data.apps[props.id]; // debugger

  var imgs = [];
  var icon;

  var disc = function disc() {
    var raw = entr.disc;
    var introL = +raw[3];
    raw = raw.slice(0, 3) + raw.slice(4);
    var arr = raw.split('\n');
    return {
      intro: arr.slice(0, introL).join(''),
      full: arr.slice(introL).join('')
    };
  };

  $onMounted(function (mount) {
    setTimeout(function () {
      stImages(entr.imgs).then(function (d) {
        imgs = d;
        $update();
        setTimeout(function () {
          var swiper = app.swiper.create(mount[0].querySelector('.swiper'), {
            slidesPerView: "auto",
            centeredSlides: true,
            spaceBetween: 30,
            grabCursor: 1
          });
        }, 10);
      });
      stImages(entr.ico).then(function (d) {
        icon = d;
        $update();
      });
    }, 0);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id='appsPopup' class="popup">
    <div class="view">
      <div class="handler" id="appsHandler"></div>
      <div class="page">
        <div class="page-content p">
          <h1>${entr.name}</h1>
          
          <div class="meta">
            <img src="${icon}" alt="" class="icon"/>
            <a href="${entr.href}" class="link external"><i class="f7-icons">globe</i>Открыть</a>
          </div>

          <div class="accordion-item">
            <div class="accordion-item-toggle ${disc().full.length?'':'one'}"
             innerHTML="${disc().intro}"></div>
            

    ${disc().full.length ? $h`
        <div class="accordion-item-content" innerHTML="${disc().full}"></div>
     `: $h`
    `}
          </div>

          <div class="swiper">
            <!-- Slides wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              ${imgs.map((it)=>{
                return $h` <img class="swiper-slide" src="${it}" alt="" /> `
              })}
            </div>
            <!-- Pagination, if required -->
          </div>

        </div>
      </div>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '69e3bc6d3a';
export default framework7Component;