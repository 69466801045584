import { app } from '../../js/app'

import store from '../../js/store'

export async function likeLogic(id, likes) {
  likes = +likes

  if (navigator.onLine) {
    if (store.state.appData.lastLike + 1000 < +new Date) {
      console.log('accept')
      // if (store.state.gf.getCookie(`m-like-${id}`) == undefined || store.state.gf.getCookie(`m-like-${id}`) == '0') {

      app.request({
        url: `https://${store.state.appData.url}/static/php/like.php?_nch${+new Date}`,
        cache: false,
        method: "post",
        data: {
          postId: id,
          storage: localStorage[`m-like-${id}`] == undefined ? '0' : localStorage[`m-like-${id}`]
        }
      }).then((res) => {
        let dat = JSON.parse(res.data)
        console.log(dat)
        let rlikes = dat.likes
        app.emit('e-liked', rlikes);
      })


      if (localStorage[`m-like-${id}`] == undefined || localStorage[`m-like-${id}`] == '0') {

        navigator.vibrate([30, 40, 45])
        document.querySelector('.like').style.animation = 'like 0.5s ease forwards'
        localStorage[`m-like-${id}`] = '1';

      } else {
        document.querySelector('.like').style.animation = 'Rlike 0.5s ease forwards'
        localStorage[`m-like-${id}`] = '0';

      }


      store.state.appData.lastLike = +new Date
    }
  } else {
    app.toast.show({
      text: 'без интернета нельзя',
      closeTimeout: 3000,
      cssClass: "error"
    })
  }

}