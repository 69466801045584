/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $update = _ref.$update;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">
    <!-- <div class="shtor"></div> -->
    <div class="view view-main view-init safe-areas">
      <q-home></q-home>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '66946c99b0';
export default framework7Component;