import { gallery } from './gallery'
import { grey } from './grey'
import { bold } from './bold'
import { parallax } from './parallax'
import { del } from './del'
import { video } from './video'
import { file } from './file'
import {code} from './code'


export function content_parse(datar, app) {
  let content = datar.content;
  while (content.indexOf('::') !== -1) {
    let str = content.slice(content.indexOf('::')).slice(0, content.slice(content.indexOf('::') + 2).indexOf('::') + 4);
    let arr = str.slice(2, str.length - 2).split("^");
    let altarr = arr[1].split(',,')
    let newstr = 'nonnee';
    switch (arr[0]) {
      case 'gallery':
        newstr = gallery(arr);
        break;
      case 'grey':
        newstr = grey(arr);
        break;
      case 'bold':
        newstr = bold(arr);
        break;
      case 'ppl':
        newstr = parallax('pic', 'l', altarr, datar.name);
        break;
      case 'ppr':
        newstr = parallax('pic', 'r', altarr, datar.name);
        break;
      case 'pbl':
        newstr = parallax('text', 'l', altarr);
        break;
      case 'pbr':
        newstr = parallax('text', 'r', altarr);
        break;
      case "del":
        newstr = del(altarr);
        break;
      case 'video':
        newstr = video(arr);
        break;
      case 'file':
        newstr = file(arr);
        break;
      case 'code':
        newstr = code(arr);
        break;
    }
    content = content.replace(str, newstr);
  }
  for (let index = 0; index < (content.split('href="http').length - 1); index++) {
    content = content.replace('href="http', `class="link external" href="http`)

  }
  for (let index = 0; index < (content.split(/\/.\w\//).length - 1); index++) {

    let reg = new RegExp(/href="\/.\w\/"/)
    let sr = content
    let regL = sr.length - sr.split(reg).join('').length
    let regSt = sr.slice(sr.search(reg), regL + sr.search(reg)) 
    content = content.replace(reg, `${regSt} data-reload-current="true"`)

  }
  let dom = new DOMParser().parseFromString(content, "text/html").querySelector('body')
  let headersEl = dom.querySelectorAll('h2')
  let headers = []
  headersEl.forEach(el => {
    if (el.textContent.indexOf(' ')==-1) {
      headers.push(el.tagName[1] + el.textContent)
    }
    
    

  });

  let li = `<ol class="glav">`;
  console.log(headers)
  if (headersEl.length > 0) {
    headers.forEach((el, i) => {
      li += `<li class="anchor" link="h${i}" style="padding-left:${(+el[0] - 2) * 0.5}rem">${el.slice(1)}</li>`
      headersEl[i].setAttribute('id', `h${i}`)
    });
  }
  li += '</ol>'
  content = dom.innerHTML
  content = li + content


  content = `<div>${content}</div>`;
app.store.state.appData.headers=headers
  return content
}

export function srcBuild(src, dir) {
  if (src[0] == '/') {
    return `https://admin.${store.state.appData.url}/uploads${src}`
  } else {
    return `./static/img/${dir}/${src}`
  }
}