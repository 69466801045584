import _defineProperty from "@babel/runtime/helpers/defineProperty";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $onMounted = _ref.$onMounted,
      $on = _ref.$on,
      $store = _ref.$store;
  var src = "https://".concat($store.state.appData.url).concat(props.data_src[0].path);
  var photos = props.data_src.map(function (it) {
    return {
      url: "https://".concat($store.state.appData.url).concat(it.path),
      caption: it.meta.title
    };
  });

  function getRandomInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  var classN = 'pb' + getRandomInRange(0, 10000000);
  var classFull = classN + ' col-100 medium-33 pngPost';
  $onMounted(function () {
    setTimeout(function () {
      var _app$photoBrowser$cre;

      var myPhotoBrowserStandalone = app.photoBrowser.create((_app$photoBrowser$cre = {
        photos: photos,
        theme: 'dark',
        navbar: true,
        toolbar: false,
        navbarShowCount: true,
        routableModals: true,
        expositionHideCaptions: true,
        url: 'img',
        popupCloseLinkText: "<i class=\"f7-icons\">".concat(app.device.desktop ? 'multiply' : 'arrow_left', "</i>")
      }, _defineProperty(_app$photoBrowser$cre, "navbarShowCount", true), _defineProperty(_app$photoBrowser$cre, "iconsColor", 'left'), _defineProperty(_app$photoBrowser$cre, "navbarOfText", 'из'), _defineProperty(_app$photoBrowser$cre, "renderNavbar", function renderNavbar(e) {
        return "\n          <div class=\"navbar navbar-photo-browser navbar-photo-browser-dark\">\n              <div class=\"navbar-inner navbar-inner-centered-title sliding\" style=\"justify-content: flex-start;\">\n                <div class=\"left\">\n                  <a class=\"link popup-close wide rem2_4Auto\" data-popup=\".photo-browser-popup\">\n                    <i class=\"f7-icons\">".concat(app.device.desktop ? 'multiply' : 'arrow_left', "</i>\n                  </a>\n                </div>\n\n                <div class=\"title\" style=\"left: 0px;\"><span class=\"photo-browser-current\">1</span><span class=\"photo-browser-of\">\u0438\u0437</span><span class=\"photo-browser-total\">4</span></div>\n\n              </div>\n            </div>");
      }), _defineProperty(_app$photoBrowser$cre, "swiper", {
        speed: 300,
        preloadImages: true,
        navigation: {
          nextEl: '.photo-browser-next',
          prevEl: '.photo-browser-prev'
        },
        zoom: {
          maxRatio: 2.4
        },
        lazy: {
          enabled: true
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false
        },
        mousewheel: {
          invert: false
        }
      }), _app$photoBrowser$cre));
      myPhotoBrowserStandalone.on('close', function () {
        myPhotoBrowserStandalone.el.style.opacity = '0';
      });
      document.addEventListener("keydown", function (event) {
        if (event.isComposing || event.keyCode === 27) {
          //esc
          myPhotoBrowserStandalone.close();
          return;
        }
      });
      $(".".concat(classN)).on('click', function () {
        myPhotoBrowserStandalone.open();
      });
    }, 100);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <a class=${classFull} href="#">
    <img data-src=${src} class="lazy" alt=${props.alt} />
    <img data-src=${src} class="lazy imgUnder" alt=${props.alt} />
  </a>
`
    }
    ;
}

framework7Component.id = '03d53a437e';
export default framework7Component;