/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7;
  var toolt;
  setTimeout(function () {
    toolt = $f7.tooltip.create({
      targetEl: '.contactsBtn',
      text: 'ссылки на меня',
      offset: 10,
      on: {
        show: function show(e) {
          if (e.targetEl.className.indexOf('fab-opened') !== -1) {
            setTimeout(function () {
              toolt.hide();
            }, 1);
          }
        }
      }
    });
  }, 100);

  function hide() {
    toolt.hide();
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div @click=${hide} class="fab fab-right-bottom ripple-color-black contactsBtn">
    <a class="no-active-state ripple overflow-hidden" href="#">
      я
    </a>
    <!-- Speed Dial action buttons -->
    <div class="fab-buttons fab-buttons-top">
      ${$store.state.data.contacts.map((item) => $h`
        <a href='${item.href}' class="fab-label-button external" target="blank">
          <!-- original button content -->
          <span
            style='background-image: url(/static/img/${item.img}); width: 100%; height: 100%; background-size: cover;' 
            class='ripple overflow-hidden'></span>
          <!-- button label -->
          <span style="pointer-events: all;" class="fab-label ripple overflow-hidden">${item.disc}</span>
        </a>
      `)}
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'dfe98d317c';
framework7Component.style = "\n  .contactsBtn > a{\n    width: 100%;\n    height: 100%;\n    border-radius: 1000px;\n  }\n\n  .contactsBtn {\n    border-radius: 1000px;\n  }\n";
export default framework7Component;