/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $ = _ref.$,
      $update = _ref.$update;
  $f7.on('e-pngsLoaded', function () {
    $update(function () {
      setTimeout(function () {
        $f7.lazy.create('#tab2');
      }, 10);
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="content page-wrapper png">
    <div class="row">
      ${$store.state.data.pngs.map((item) => $h`
        <q-pngPost data_src=${item.img} alt=${item.alt}></q-pngPost>
      `)}
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '19c3d161d4';
export default framework7Component;