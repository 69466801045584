import $ from "dom7";
import store from "../../store";
import { app } from "../../app";

import colorF from 'color'

export function s_global_events(e) {
    let curDevice = store.state.appData.desktop ? 'desktop' : 'mobile'
    $(document).on('click', '.photo-browser-page .rotateBtn', (e) => {
        $(e.target.closest('.photo-browser-page').querySelector('.swiper-slide-active img')).toggleClass('rotate')
        if (!e.target.classList.contains('rotate')) {
            app.photoBrowser.get(e.target.closest('.photo-browser-popup')).expositionEnable()
        } else {
            app.photoBrowser.get(e.target.closest('.photo-browser-popup')).expositionDisable()
        }
        $(e.target).toggleClass('rotate')


    })
    window.q = $
    q(document).on('click', 'li.anchor', (e) => {
        let sec = Math.abs((q('.page-current .page-content').scrollTop() - (document.querySelector(`#${e.target.getAttribute('link')}`).offsetTop - 70)) / 3)
        // debugger
        console.log(sec)
        q('.page-content.single').scrollTop(document.querySelector(`#${e.target.getAttribute('link')}`).offsetTop - 70,
            sec)



    })
    q(document).on('click', (e) => {
        if (1) {
            let lastEl = document.querySelector('.chip.expanded')
            if (lastEl) lastEl.classList.remove('expanded')
        }
    })


    //инит тултипов
    setInterval(() => {
        for (let index = 0; index < document.querySelectorAll('.ttip:not([init="1"])').length; index++) {
            const element = document.querySelectorAll('.ttip:not([init="1"])')[index]
            if (element.getAttribute('data-only') == null || element.getAttribute('data-only') == curDevice) {

                let ttext = element.getAttribute('ttipText')
                console.log('create', element.parentElement)

                try { app.tooltip.get(element).destroy() } catch (error) { }
                app.tooltip.create({
                    targetEl: element,
                    text: ttext,
                    cssClass: (element.getAttribute('delay') !== null ? 'd' + element.getAttribute('delay') : '') + (element.getAttribute('tail') !== null ? ' tail ' + element.getAttribute('tail') : ''),
                    on: {
                        show: function (e) {
                            setTimeout(() => {
                                if (document.querySelector('.tooltip-in') !== null) {
                                    app.store.state.gf.vibrate('lil success')
                                }
                            }, 400)
                            if (element.getAttribute('ttipCondition') !== null && !eval(element.getAttribute('ttipCondition'))) {
                                e.hide()
                                setTimeout(() => {
                                    e.hide()
                                }, 20)
                                setTimeout(() => {
                                    e.hide()
                                }, 70)
                                setTimeout(() => {
                                    e.hide()
                                }, 170)
                                setTimeout(() => {
                                    e.hide()
                                }, 470)
                                setTimeout(() => {
                                    e.hide()
                                }, 970)

                            }
                        }
                    }
                })
            }
            element.setAttribute('init', 1)
        }
    }, 500)

    setInterval(() => {
        if (document.querySelector('.modal-in:not(.toast)') !== null) {
            shadow()

            if ((document.querySelector('.modal-in.actions-modal') !== null || document.querySelector('.modal-in.dialog:not(.upd)') !== null || document.querySelector('.modal-in.popover') !== null || document.querySelector('.modal-in.act') !== null) && !store.state.appData.modalOpened) {
                store.state.appData.modalOpened = true
                window.history.pushState('forward', null, './#modal')
            }
        } else {
            if (store.state.appData.modalOpened) {
                store.state.appData.modalOpened = false
                history.back()
            }
        }
    }, 300)

    $(window).on('popstate', function () {
        // $('#myModal').modal('hide');
        closeModal(true)
    })

    function closeModal(nohistory) {
        if (store.state.appData.modalOpened) {
            console.log('hide')
            store.state.appData.modalOpened = false
            if (!nohistory) {
                history.back()
            }
            try { app.actions.get('.actions-modal.modal-in').close() } catch (error) { }
            try { app.actions.get('.dialog.modal-in').close() } catch (error) { }
            try { app.actions.get('.popover.modal-in').close() } catch (error) { }
            try { store.state.gf.bg({ act: 'close' }) } catch (error) { }

        }
    }

    function setShapka(c, before) {
        document.head.querySelector('#metaColor').setAttribute('content', c)
        $('.shtor').css('background-color', c)
        if (before == undefined) {
            document.head.querySelector('#metaColor').setAttribute('contentB', c)
            // document.head.querySelector('.shtor').setAttribute('contentB', c)
        }
        
    }

    function setTheme(col) {
        // debugger
        let c = colorf(col).lighten(0.65).desaturate(0.05).hex()

        if (document.body.classList.contains('theme-dark')) {
            c = colorf(col).darken(0.35).desaturate(0.2).hex()
        }

        setShapka(c)
    }

    function shadow() {
        if (!document.body.classList.contains('theme-dark')) {
            setShapka(colorf(document.head.querySelector('#metaColor').getAttribute('contentB')).darken(0.4).desaturate(0.6).hex(), 1)
        }
    }

    if (1) {
    

        function change() {
            // console.log('set')
            let c = {
                mainD: '#6b4124',
                mainN: '#53091b',
                bgD: '#f5f5f5',
                bgN: '#181313'
            }
            window.colorf = colorF
            // console.log('set')
            if (document.querySelector('.modal-in:not(.toast)') == null) {
                if (document.querySelector('.page-current .single')) {

                    setTheme(store.state.appData.theme == 'dark' ? c.bgN : c.bgD)
                } else {

                    if (document.querySelector('.page-current .page-content').scrollTop > window.innerHeight) {
                        setTheme(store.state.appData.theme == 'dark' ? c.bgN : c.bgD)
                    } else {
                        setTheme(store.state.appData.theme == 'dark' ? c.mainN : c.mainD)
                    }
                }
            }
        }
    

        app.on('e-scroll-glob e-scroll', change)
        setInterval(() => {
            change()
        }, 300);
    }
    
}

/** Склонение слова по числам
 * @param {String[]} words Массив возможных склонений слова word. ['дней', 'день', 'дня']
 * @param {Number} word Число, с которым нужно соеденить
 * @returns {String} число + готовое слово
 */
export function getWord(words, word) {
    const n = `${word}`

    if (last(n, 1) == '0' || last(n, 1) == '5' || last(n, 1) == '6'
        || last(n, 1) == '7' || last(n, 1) == '8' || last(n, 1) == '9' || last(n, 2) == '10'
        || last(n, 2) == '11' || last(n, 2) == '12' || last(n, 2) == '13' || last(n, 2) == '14') {
        return `${n} ${words[0]}`
    } if (last(n, 1) == '1') {
        return `${n} ${words[1]}`
    } if (last(n, 1) == '2' || last(n, 1) == '3' || last(n, 1) == '4') {
        return `${n} ${words[2]}`
    }

    function last(str, c) {
        return str.slice(c * -1)
    }
}


