/** @jsx $jsx */
import { $jsx } from 'framework7';
import postt from './../post/post.f7.html';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $h = _ref.$h,
      $update = _ref.$update,
      $f7 = _ref.$f7;
  var post = false;

  function popit(posts) {
    var arr = posts.map(function (it) {
      return it.name;
    }); // debugger

    if (props.thispost.name !== arr[0] + '') {
      post = posts[arr.indexOf(props.thispost.name) - 1];
    }

    $update(function () {
      console.log(props.thispost.name, post);
      setTimeout(function () {
        $f7.emit('e-lastPostUpdated');
      }, 50);
    });
  }

  if ($store.state.appData.postsLoaded) {
    popit($store.state.data.posts);
  } else {
    $f7.on('e-postsLoaded', function (posts) {
      popit(posts);
    });
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div>
    ${post && $h`
    <div class="lastPost">
      <span>Следующий пост: </span>
      <${postt} ref='post' post=${post} />
    </div>
    `}
  </div>
`
    }
    ;
}

framework7Component.id = 'ea8cfbe57a';
export default framework7Component;