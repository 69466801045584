/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $store = _ref.$store;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">

    ${$store.state.appData.desktop && $h`
      <!-- <q-tabsDesktop></q-tabsDesktop> -->
      
      <div class="fab-backdrop"></div>
      <q-fab></q-fab>
    `}
    
    ${!$store.state.appData.desktop && $h`
      <!-- <nav class="tabsB toolbar tabbar toolbar-bottom">
        <q-tabsButt></q-tabsButt>
      </nav> -->
    `}
   

    <q-contactsBtn></q-contactsBtn>

    <div class="page-content home">
      <q-mainScreen></q-mainScreen>
      <q-about></q-about>
      
      <q-tabs></q-tabs>
      <q-footer></q-footer>
    </div>

  </div>
`
    }
    ;
}

framework7Component.id = '2b751d8851';
export default framework7Component;