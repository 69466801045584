import { srcBuild } from "./content_parse";

export function video(items) {
  console.log(items);
  let name = items[1]
  if (items[1].split(',,')[1]) {
    name = app.device.prefersColorScheme() == 'light' ? items[1].split(',,')[0] : items[1].split(',,')[1]
  }
  console.log(name)
  return `
  <video data-src="${srcBuild(name)}" muted="true" autoplay="true" loop></video>`
}