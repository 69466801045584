import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $update = _ref.$update,
      $f7 = _ref.$f7;

  function countCategories(params) {
    var arr = $store.state.data.posts.map(function (it, n) {
      return it.type;
    });
    var all = arr.length;

    var cats = _toConsumableArray(new Set(arr));

    for (var key in $store.state.data.categories) {
      var element = $store.state.data.categories[key];
      element.num = arr.join('').split(element.name).length - 1;
    }

    $store.state.data.categories[0].num = all; // let catssss = cats.map((it)=>{
    //   return {
    //     ...cats 
    //   }
    // })
  }

  $f7.on('e-postsLoaded', function () {
    countCategories();
    $update();
  });

  function activeCheck(c) {
    return c.name == $store.state.appData.activeCategory ? 'active' : '';
  }

  function changeCategory(it) {
    $store.state.appData.activeCategory = it;
    $update(function () {
      $f7.emit('e-postsLoaded');
    });
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div style="position: relative;">
    <div class="leftSh shadow"></div>
    <section class="categories"> ${$store.state.data.categories.map((it)=>$h` <div @click=${()=>
        {changeCategory(it.name)}} class="chip chip-outline ${activeCheck(it)} "> <div class="chip-label">${it.runame}:
          <span class="num"> ${it.num} шт. </span>
        </div>
      </div> `)} </section>
    <div class="rightSh shadow"></div>
  </div>
`
    }
    ;
}

framework7Component.id = 'c691e42467';
export default framework7Component;