/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $onMounted = _ref.$onMounted,
      $f7 = _ref.$f7,
      $ = _ref.$,
      $store = _ref.$store;
  $onMounted(function (mount) {
    var el = mount[0];
    console.log($(el));
    setInterval(function () {
      var progW = el.firstChild.offsetWidth;
      var hudW = el.childNodes[1].offsetWidth;

      if ($store.state.appData.tab == 1) {
        $(el).addClass('top');
        $(el).attr('style', "--width:".concat(progW, "px;"));
      } else {
        $(el).removeClass('top');
        $(el).attr('style', "--width:".concat(hudW, "px;"));
      }
    }, 400);
    app.on('e-scroll', function () {
      var homeTop = document.querySelector('.content.page-wrapper.home').getBoundingClientRect().top;
      var pngTop = document.querySelector('.content.page-wrapper.png').getBoundingClientRect().top;

      if (homeTop < 0 || pngTop < 0) {
        el.classList.add('act');
      } else {
        el.classList.remove('act');
      }
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="navLinks">
    <a class="li tab-link tab-link-active" href="#tab1">программист дизайнер</a>
    <a class="li tab-link" href="#tab2">художник</a>
  </div>
`
    }
    ;
}

framework7Component.id = '24d0d1ee83';
framework7Component.style = "\n";
export default framework7Component;