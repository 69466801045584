/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props) {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <footer class="no-active-state">
    спасибо, что долистал до сюда
  </footer>
`
    }
    ;
}

framework7Component.id = '6e67e7a97a';
framework7Component.style = "\n";
export default framework7Component;