/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props) {
  var name = function name() {
    switch (props.type) {
      case 'order':
        return 'заказ';
        break;

      case 'update':
        return 'обновление';
        break;

      case 'mywork':
        return 'свой проект';
        break;

      case 'dev':
        return 'разработчикам';
        break;
    }
  };

  var moment = require('moment');

  moment.lang('ru');

  var date = function date() {
    return moment(props.date).format('D MMMM YYYY');
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="type">
    <img class="ava" src="static/img/${props.type}.svg" />
    <div class="meta">
      <div class="name">${name()}</div>
      <time class="time">${date()}</time>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'a4bf57e163';
export default framework7Component;