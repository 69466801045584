/** @jsx $jsx */
import { $jsx } from 'framework7';
import { stImages } from './../js/mods/imports/s-imports.js';
import { getWord } from './../js//mods/events/s-global-events.js';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update;

  function expand(ev) {
    setTimeout(function () {// ev.target.closest('.chip').classList.toggle('expanded')
    }, 1);
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
	<div @click="${expand}"  class="chip ttip" ttipText="${props.skill.disc}" style="--bg:${props.skill.bg};--col:${props.skill.color}">
		${props.skill.ico && $h` <div class="chip-media"><img src="${props.skill.ico[0]}" /></div> `}
		<div class="chip-label">${props.skill.name}: <span class="num">${props.skill.duration}</span></div>
	</div>
`
    }
    ;
}

framework7Component.id = 'bb68cc4764';
export default framework7Component;