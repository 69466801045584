/** @jsx $jsx */
import { $jsx } from 'framework7';
import { likeLogic } from './likeLogic.js';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update,
      $onMounted = _ref.$onMounted,
      $el = _ref.$el;
  var likes = props.likes;
  $onMounted(function () {
    var video = $el.value[0].childNodes[0];
    $f7.on('e-liked', function (li) {
      video.play();
      likes = li;
      $update();
    });

    if (localStorage['m-like-' + props.id] !== undefined && localStorage['m-like-' + props.id] !== '0') {
      document.querySelector('.like').style.animation = 'like 0.5s ease forwards';
    }
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <!-- <div @click=${()=>{likeLogic(props.id, props.likes)}} class="like">${likes}</div> -->
    <div class="like" @click=${()=>{likeLogic(props.id, props.likes)}}>
      <video src="static/heart.webm"></video>
    <div class="likeNum">${likes}</div>
    </div>
  
`
    }
    ;
}

framework7Component.id = '3080b22754';
framework7Component.style = "\n  .like video{\n    height: 100%;\n    filter: drop-shadow(0px 0px 12px);\n    padding: 1rem 0rem 0.5rem 0rem;\n    color: #e9374870;\n  }\n\n";
export default framework7Component;