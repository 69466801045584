/** @jsx $jsx */
import { $jsx } from 'framework7';
import lozad from 'lozad';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $el = _ref.$el,
      $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update;

  function sortPosts(cat) {
    if (cat == 'all') {
      return $store.state.data.posts;
    } else {
      var posts = $store.state.data.posts.map(function (it) {
        if (it.type == cat) {
          return it;
        }
      });
      posts = posts.filter(function (it) {
        if (it !== undefined) {
          return it;
        }
      });
      return posts;
    }
  }

  var options = {
    // root: document.querySelector( '#viewport' ), // я закомментил строку, чтобы использовать значение по умолчанию
    rootMargin: '50% 0px',
    root: document.getElementsByClassName('page-content')[0]
  };
  var observer = new IntersectionObserver(trueCallback, options); // callback-функция (возвратная функция)

  function trueCallback(entries, observer) {
    entries.forEach(function (entry) {
      var target = entry.target,
          isIntersecting = entry.isIntersecting;

      if (isIntersecting) {
        console.log('сработало', entry.target);
        entry.target.src = entry.target.getAttribute('data-src');
      } // делаем что-либо для каждого переданного элемента (в нашем случае он один)

    });
  }

  $f7.on('e-postInited', function (imgs) {
    imgs.forEach(function (el) {
      return observer.observe(el);
    });
  });
  $f7.on('e-postsLoaded', function () {
    $update(function () {
      setTimeout(function () {
        $($el.value[0].querySelector('.skelet')).css('display', 'none');
        $f7.lazy.create('.page-content');
      }, 1);
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <section class="content page-wrapper home" id="pageWrapper">
    ${sortPosts($store.state.appData.activeCategory).map((item) => $h`
        <q-post ref='main' key=${item._id} post=${item}></q-post>
    `)}
    <div class="skelet">
      <div class="skeleton-block skeleton-effect-wave"></div>
      <div class="skeleton-block skeleton-effect-wave"></div>
      <div class="skeleton-block skeleton-effect-wave"></div>
    </div>
  </section>
`
    }
    ;
}

framework7Component.id = 'ed21092b1c';
export default framework7Component;