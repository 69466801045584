/** @jsx $jsx */
import { $jsx } from 'framework7';
import lottie from 'lottie-web';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update;
  var content = "\n    <h2 class=\"skeleton-text skeleton-effect-wave\">____ ______</h2>\n    <p class=\"skeleton-text skeleton-effect-wave\">.... ..... ..... ..... ..... ..... ...... ..... ....... ..... ..... ..... ..... ..... ...... ..... ....... ..... ..... ..... ..... ..... ...... ..... ...</p>\n    <p class=\"skeleton-text skeleton-effect-wave\">.... ..... ..... ..... ..... ..... ...... ..... ....... </p>\n    ";
  var ani = 'hi';

  function create_content() {
    var data = $store.state.data.about;
    var name = data.map(function (it) {
      return it.name;
    });
    var vals = Object.values(data);

    for (var i = 0; i < Object.keys(data).length; i++) {
      var keyEl = name[i];
      var valEl = vals[i];

      if (valEl.text.indexOf("lot_hi") != -1) {
        valEl.text = valEl.text.replace("lot_hi", "<div class='lottie hi'></div>");
        console.log('replaced');
      }

      if (valEl.text.indexOf("lot_paint") != -1) {
        valEl.text = valEl.text.replace("lot_paint", "<div class='lottie paint'></div>");
        console.log('replaced');
        ani = 'paint';
      }

      if ($store.state.appData.tab == keyEl) {
        content = valEl.text;
      }
    }

    $update();
  }

  $f7.on('e-aboutLoaded', function () {
    create_content();
    setTimeout(function () {
      // debugger
      var animation = lottie.loadAnimation({
        container: document.querySelector(".lottie.hi"),
        // Required
        path: "static/hi.json",
        // Required
        renderer: 'svg',
        loop: true,
        autoplay: true,
        // Optional
        rendererSettings: {
          progressiveLoad: true // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.

        }
      });
    }, 100);
  });
  $f7.on('e-tabChange', function (tab) {
    console.log(tab);
    create_content();
    setTimeout(function () {
      // debugger
      var animation = lottie.loadAnimation({
        container: document.querySelector(".lottie.".concat(tab == 1 ? 'hi' : 'paint')),
        // Required
        path: "static/".concat(tab == 1 ? 'hi' : 'paint', ".json"),
        // Required
        renderer: 'svg',
        loop: true,
        autoplay: true,
        // Optional
        rendererSettings: {
          progressiveLoad: true // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.

        }
      });
    }, 100);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <section id="about">
    <div class="pageWrapper" innerHTML=${content}></div>
  </section>
`
    }
    ;
}

framework7Component.id = '85f2289334';
framework7Component.style = "\n  .lottie {\n    height: 25pt;\n    display: inline-block;\n    transform: translateY(20%);\n  }\n\n  .lottie.paint {\n    height: 25pt;\n  }\n";
export default framework7Component;