import { app } from '../../js/app'
import store from '../../js/store';
import rellax from 'rellax';
import { srcBuild } from './content_parse';

window.Rellax=rellax

export function parallax(what, side, items, postname) {
  let too = `too${store.state.gf.getRandomInRange(0, 10000000000)}`;
  let res

  // console.log(items);
  if (what == 'pic') {
    console.log(items)
    res = `</p>
    <img src="${srcBuild(items[0],postname)}" data-rellax-speed="-3" data-rellax-percentage="-1000" class="paralBlock rellax pp${side} ${items[1] == 'inv' ? 'invertImg' : ''}">
    <p>
    `
  }else if(what=='text'){
    res=`<div style=" font-size: ${items[2]};" data-rellax-speed="-3" data-rellax-percentage="-10000" class="paralBlock rellax pt${side} ${items[1]=='inv'?'invertImg':''}">${items[0]}</div>`
  }
  return res
}