/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $el = _ref.$el;
  $f7.on('e-update', function () {});

  function calle() {
    if (props.text) {
      setTimeout(function () {
        // debugger
        $f7.emit('e-readyToIntersect', $el.value[0].querySelectorAll('[data-src]'));
      }, 100);
    }
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div>
    <div> ${props.text ? ($h` <div>
        <div a="${calle()}" innerHTML=${props.text}></div>
      </div> `): $h` <div class="title-large skeleton-text skeleton-effect-wave">----- - ----- ----</div>
      <div class="time skeleton-text skeleton-effect-wave">-- ----- ----</div>
      <br />
      <div>
        <div class="skeleton-block skeleton-effect-wave" style="width: 15rem; height: 3rem;"></div>
      </div>
      <h2 class="skeleton-text skeleton-effect-wave">____ __________</h2>
      <p class="skeleton-text skeleton-effect-wave">_____ _________ _____ _____ __________ _____ _______ _____
        ___________ ___ _____ ______ _____ ________ ________ _______ _______ ____ ____ -________</p>
      <p class="skeleton-text skeleton-effect-wave">______ _______ _____ _____ _____ __________ ___ _______ ______ ____
        ____ ___ __________ _______ ______ ______</p>
      <p>
      <div class="skeleton-block skeleton-effect-wave" style="width: 80vw; height: 20rem;"></div>
      </p> `}
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'c33aa1f8d0';
export default framework7Component;