/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $el = _ref.$el,
      $onMount = _ref.$onMount;

  function tabActive(params) {
    $store.state.appData.page = props.tab;

    for (var index = 0; index < $el.value[0].parentElement.children.length; index++) {
      var element = $el.value[0].parentElement.children[index];
      element.classList.remove('tab-link-active');
      element.firstElementChild.innerText = element.firstElementChild.innerText.split('_')[0];
    }

    $el.value[0].classList.add('tab-link-active');
    $el.value[0].firstElementChild.innerText += '_fill';
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <a href="#tab${props.tab}" @click=${tabActive} class='tab-link tabsB-item ${props.cl}'>
    <i class="f7-icons">${props.text}</i>
  </a>
`
    }
    ;
}

framework7Component.id = '1ecbcf4c8c';
export default framework7Component;