/** @jsx $jsx */
import { $jsx } from 'framework7';
import apps from '../apps.f7.html';
import skills from '../skills.f7.html';

function framework7Component(props, _ref) {
  var $on = _ref.$on,
      $f7 = _ref.$f7,
      $store = _ref.$store,
      $el = _ref.$el;
  $on('tabShow', function (e) {
    $store.state.appData.tab = e.target.id.slice(-1);
    $f7.emit('e-tabChange', e.target.id.slice(-1));
    var num = e.target.id.slice(-1);
    var tabEl = document.querySelector(".tabsB-item[href='#tab".concat(num, "']"));
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="tabs">
    <div class="tab tab-active" id="tab1">
      <${skills} />
      <${apps} />
      <q-categories></q-categories>
      <div class="page-content">
        <q-rhome></q-rhome>
      </div>
    </div>
    <div class="page-content tab" id="tab2">
      <q-png></q-png>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '186ee34021';
export default framework7Component;