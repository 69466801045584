/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $el = _ref.$el,
      $store = _ref.$store,
      $onMounted = _ref.$onMounted;
  var formatthumb = props.post.thumbwebp.data.attributes.url;

  function remember() {
    app.store.state.appData.myRoute = 1;
  }

  $onMounted(function () {
    setTimeout(function () {
      var swiper = $f7.swiper.create($el.value[0], {
        speed: 300,
        resistanceRatio: 0.1,
        spaceBetween: 0,
        navigation: {
          nextEl: '.snext',
          prevEl: '.sprew',
          disabledClass: 'dis',
          lockClass: 'dis',
          hiddenClass: 'dis'
        },
        mousewheel: {
          invert: false
        },
        on: {
          slideChangeTransitionEnd: function slideChangeTransitionEnd(s) {
            var prev = s.navigation.$prevEl[0];
            var next = s.navigation.$nextEl[0];

            if (!s.isBeginning) {
              prev.style.opacity = '0.7';
            } else {
              prev.style.opacity = '0';
            }

            if (!s.isEnd) {
              next.style.opacity = '0.7';
            } else {
              next.style.opacity = '0';
            }
          }
        }
      });
    }, 50);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="swiper-container gal">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <a href="/${props.post.name}/" @click="${remember}" data-reload-current="${props.reload}">
          <img data-src="https://admin.${$store.state.appData.url}${formatthumb}"
            class="lazy post-img" />
        </a>
      </div> ${props.post.gall==null?'':props.post.gall.data.map((item)=>$h` <div class="swiper-slide">
        <a href="/${props.post.name}/" @click="${remember}" data-reload-current="${props.reload}">
          <img data-src="https://admin.${$store.state.appData.url}${item.attributes.url}"
            style="object-fit: contain" class="lazy post-img" />
          <div class="bg"></div>
        </a>
      </div> `)}
    </div>
    <i class="f7-icons snext size-22">arrow_right</i>
    <i class="f7-icons sprew size-22">arrow_left</i>
  </div>
`
    }
    ;
}

framework7Component.id = '41c443380c';
export default framework7Component;