/** @jsx $jsx */
import { $jsx } from 'framework7';
import { likeLogic } from './likeLogic.js';
import like from './like.f7.html';

function framework7Component(props, _ref) {
  var $store = _ref.$store,
      $f7 = _ref.$f7,
      $update = _ref.$update,
      $onMounted = _ref.$onMounted;
  var likes = props.likes;
  $f7.on('e-liked', function (li) {
    likes = li;
    $update();
  });

  function toComm() {
    q('.page-current .page-content').scrollTop(q('#anycomment-app')[0].offsetTop, 400);
    console.log('toComm');
  }

  $onMounted(function () {
    if (localStorage['m-like-' + props.id] !== undefined && localStorage['m-like-' + props.id] !== '0') {// document.querySelector('.like').style.animation='like 0.5s ease forwards'
    }
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  ${!$store.state.appData.desktop ?  $h`
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
          <div class="top scrollHeaders">
            ${$store.state.appData.headers.map((item, i) => $h` 
              <li class="anchor" link="h${i}">${item.slice(1).length>15?(item.slice(1,15+1)+'..'):item.slice(1)}</li>
            `)}
          </div>
            <q-contactsBtn
              style="color: rgba(255, 255, 255, 0.767);position: relative;transform: none !important;width: 2.4rem;height: 2.4rem;font-size: 13pt;"
              class="vibrate50" >
            </q-contactsBtn>
            <a class="ttip link" ttipText="В комментарии"><i @click="${toComm}" class="f7-icons commentsLinkChat">chat_bubble_2</i></a>
            <a class="home link ttip backk vibrate50 ${!$store.state.appData.myRoute?'external':'back'}" 
            href="${!$store.state.appData.myRoute?'https://'+$store.state.appData.url:''}" ttipText="На главную"></a>
            <!-- <${like} id=${props.id} likes=${props.likes} m=1 /> -->
      </div>
    </div>
    `:$h`
    <div></div>
    `
  }
`
    }
    ;
}

framework7Component.id = 'adb122ff2e';
export default framework7Component;